html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
video {
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
}

a {
  color: inherit;
}

.participant-container {
  position: relative;
  min-height: 100px;
}
.participant-container .participant-name {
  border-radius: 10px 10px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: block;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
}

.participant-container .participant-name i {
  float: right;
  color: red;
}
.participant-container .participant-name i.fa-microphone {
  display: none;
}
audio[data-enabled="true"] + .participant-name .fa-microphone-slash {
  display: none;
}
audio[data-enabled="true"] + .participant-name .fa-microphone {
  display: initial;
  color: green;
}

.remote-media .participant-container {
  width: calc(100% - 10px);
  margin-bottom: 20px;
  float: left;
}
@media (min-width: 600px) {
  .remote-media .participant-container {
    width: calc(50% - 10px);
  }
  .remote-media .participant-container:nth-child(odd) {
    clear: both;
  }

  .remote-media .participant-container:nth-child(odd) {
    margin-left: 0;
    margin-right: 10px;
  }
  .remote-media .participant-container:nth-child(even) {
    margin-right: 0;
    margin-left: 10px;
  }
}

audio[data-enabled="false"] + .participant-name .fa-microphone {
  display: none;
}
audio[data-enabled="false"] + .participant-name i {
  color: red;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(50,50,50,1);
  border-radius: 10px;
}

.placeholder i {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.remote-media .placeholder i {
  font-size: 30px;
}

video[data-enabled="true"] + .placeholder {
  display: none;
}
